// src/App.tsx
import React, { createContext, useState, useEffect, lazy, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
// import { auth } from './config/firebase';
import axios from "axios";
import Navigation from "./components/Navigation";
import ScrollToTopArrow from "./components/ScrollToTopArrow";
import Footer from "./templates/Footer";
import ScrollToTop from "./components/ScrollToTop";
import LoadingSpinner from "./components/LoadingSpinner";
import { auth } from "./config/firebase";
// Lazy load components
const Home = lazy(() => import("./pages/Home"));
const PrivacyNotice = lazy(() => import("./pages/PrivacyNotice"));
const CarrierSetupPage = lazy(() => import("./pages/CarrierSetup"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Account = lazy(() => import("./pages/Account"));
const Profile = lazy(() => import("./pages/Profile"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const AdminDashboard = lazy(() => import("./pages/AdminDashboard"));

export type SectionName = "home" | "about" | "services" | "contact" | "privacy" | "faq" | "carrier-setup" | "login" | "register" | "dashboard";

interface AuthContextType {
  user: User | null;
  userRoles: string[];
}

const AuthContext = createContext<AuthContextType>({ user: null, userRoles: [] });

export const useAuth = () => React.useContext(AuthContext);

const ProtectedRoute: React.FC<{ element: React.ReactElement; requiredRoles?: string[] }> = ({ element, requiredRoles }) => {
  const { user, userRoles } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      if (user) {
        setLoading(false);
      } else {
        // Short delay to avoid flash of loading state for quick auth checks
        setTimeout(() => setLoading(false), 500);
      }
    };
    checkAuth();
  }, [user]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (requiredRoles && !requiredRoles.some((role) => userRoles.includes(role))) {
    return <Navigate to="/" replace />;
  }

  return element;
};

function App() {
  const [user, setUser] = useState<User | null>(null);
  const [userRoles, setUserRoles] = useState<string[]>([]);
  const sectionRefs = React.useRef<{ [key in SectionName]: HTMLElement | null }>({
    home: null,
    about: null,
    services: null,
    contact: null,
    privacy: null,
    faq: null,
    "carrier-setup": null,
    login: null,
    register: null,
    dashboard: null,
  });

  const scrollToSection = React.useCallback((section: SectionName) => {
    const element = sectionRefs.current[section];
    if (element) {
      const offsetTop = 63; // Adjust this value as needed
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offsetTop;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        try {
          const idToken = await user.getIdToken();
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URI}/api/auth/user-info`, {
            headers: { Authorization: `Bearer ${idToken}` },
          });
          setUserRoles(response.data.roles);
        } catch (error) {
          console.error("Error fetching user roles:", error);
          setUserRoles([]);
        }
      } else {
        setUserRoles([]);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, userRoles }}>
      <BrowserRouter>
        <div className="overflow-hidden pt-16">
          <Navigation scrollToSection={scrollToSection} />
          <main className="flex-grow min-h-screen">
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route path="/" element={<Home sectionRefs={sectionRefs} scrollToSection={scrollToSection} />} />
                <Route path="/privacy-notice" element={<PrivacyNotice />} />
                <Route path="/carrier-setup" element={<CarrierSetupPage />} />
                <Route path="/account" element={<Account auth={auth} />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/profile" element={<ProtectedRoute element={<Profile auth={auth} />} />} />
                <Route path="/dashboard" element={<ProtectedRoute element={<AdminDashboard />} requiredRoles={["admin"]} />} />
              </Routes>
            </Suspense>
          </main>
          <ScrollToTop />
          <Suspense fallback={<FooterSkeleton />}>
            <Footer scrollToSection={scrollToSection} />
          </Suspense>
          <ScrollToTopArrow />
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;

const PageSkeleton = () => (
  <div className="animate-pulse">
    <div className="h-64 bg-gray-200 mb-4"></div>
    <div className="h-32 bg-gray-200 mb-4"></div>
    <div className="h-32 bg-gray-200 mb-4"></div>
  </div>
);

const FooterSkeleton = () => <div className="h-40 bg-gray-200"></div>;
