import React, { FormEvent, useState } from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { SectionName } from "../App";
import axios from "axios";
interface FooterProps {
  scrollToSection: (section: SectionName) => void;
}
interface FormData {
  name: string;
  email: string;
  phone: string;
}
const Footer: React.FC<FooterProps> = ({ scrollToSection }) => {
  const currentYear = new Date().getFullYear();
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage("");

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URI}/api/newsletter/subscribe`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        setSubmitMessage("Thank you for subscribing!");
        setFormData({ name: "", email: "", phone: "" });
      } else {
        setSubmitMessage("An error occurred. Please try again.");
      }
    } catch (error) {
      console.error("Subscription error:", error);
      setSubmitMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const navItems: SectionName[] = ["home", "about", "services", "contact"];

  return (
    <motion.footer className="bg-gradient-to-r from-gray-800 to-gray-900 text-white" initial="hidden" whileInView="visible" viewport={{ once: false, amount: 0.1 }} variants={containerVariants}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div className="grid grid-cols-1 md:grid-cols-4 gap-8" variants={containerVariants}>
          {/* Company Logo and Name */}
          <motion.div variants={itemVariants}>
            <NavLink to="/" className="text-2xl">
              <span className="text-blue-400 font-bold">R</span>
              <span className="text-white font-semibold tracking-wider">emberg</span>
              <span className="text-blue-300 font-light ml-1">Logistics</span>
              <span className="text-gray-400 text-xl ml-1">LLC</span>
            </NavLink>
            <p className="mt-2 text-gray-300">Your trusted logistics partner</p>
          </motion.div>

          {/* Quick Links */}
          <motion.div variants={itemVariants}>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              {navItems.map((link) => (
                <motion.li key={link} variants={itemVariants}>
                  <button onClick={() => scrollToSection(link)} className={`hover:text-gray-300 focus:text-blue-300`}>
                    {link.charAt(0).toUpperCase() + link.slice(1)}
                  </button>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          {/* Contact Information */}
          <motion.div variants={itemVariants}>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <motion.li variants={itemVariants}>
                <a href="mailto:Dispatch@remberglogistics.com" className="hover:text-gray-300">
                  Dispatch@remberglogistics.com
                </a>
              </motion.li>
              <motion.li variants={itemVariants}>
                <a href="tel:+16785282671" className="hover:text-gray-300">
                  678-528-2671
                </a>
              </motion.li>
              <motion.li variants={itemVariants}>
                8 The Green suite #4186
                <br />
                Dover, DE 19901
              </motion.li>
            </ul>
          </motion.div>

          {/* Newsletter Signup */}
          <motion.div variants={itemVariants}>
            <h3 className="text-lg font-semibold mb-4">Newsletter</h3>
            <form className="mt-4 space-y-2" onSubmit={handleSubmit}>
              <motion.input type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="Your name" className="w-full px-3 py-2 text-gray-700 bg-gray-200 rounded" whileFocus={{ scale: 1.05 }} />
              <motion.input type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Your email" className="w-full px-3 py-2 text-gray-700 bg-gray-200 rounded" whileFocus={{ scale: 1.05 }} />
              <motion.input type="tel" name="phone" value={formData.phone} onChange={handleInputChange} placeholder="Your phone number" className="w-full px-3 py-2 text-gray-700 bg-gray-200 rounded" whileFocus={{ scale: 1.05 }} />
              <motion.button type="submit" className="mt-2 w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} disabled={isSubmitting}>
                {isSubmitting ? "Submitting..." : "Subscribe"}
              </motion.button>
            </form>
          </motion.div>
        </motion.div>

        {/* Bottom bar */}
        <motion.div className="mt-8 pt-8 border-t border-gray-700 flex flex-col md:flex-row justify-between items-center" variants={containerVariants}>
          {/* <motion.p variants={itemVariants}>&copy; {currentYear} Remberg Logistics LLC. All rights reserved.</motion.p> */}
          <motion.div variants={itemVariants}>
            <p>&copy; {currentYear} Remberg Logistics LLC. All rights reserved.</p>
            <p className="mt-2 text-sm text-gray-400">
              Developed by{" "}
              <a
                href="https://www.instagram.com/eugeneskomorokhov" 
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-400 hover:text-blue-300"
              >
                Eugene Skomorokhov
              </a>
            </p>
          </motion.div>

          <motion.div className="flex mt-4 md:mt-0 space-x-4" variants={containerVariants}>
            {/* Social media icons */}
            <motion.a href="#" className="text-gray-400 hover:text-white" variants={itemVariants} whileHover={{ scale: 1.2 }}>
              <span className="sr-only">Facebook</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </motion.a>
            <motion.a href="#" className="text-gray-400 hover:text-white" variants={itemVariants} whileHover={{ scale: 1.2 }}>
              <span className="sr-only">Instagram</span>
              <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </motion.a>
          </motion.div>
        </motion.div>
      </div>
    </motion.footer>
  );
};

export default Footer;
